<template>
  <div class="footerBackground">
    <div class="footer">
      <div class="w900">
        <!-- 尾部导航 -->
        <div class="footer-txt">
          <div class="fl cooperativePartner">
            <img src="../../assets/hometop/logo-rakumart-europe.svg" alt="" />
            <p class="tit">{{ $fanyi('以客户为中心') }}</p>
            <p class="email">service@rakumart.us</p>
            <p class="time">{{ $fanyi('工作日上午 8:30 至下午 5:30') }}</p>
            <div class="icons">

              <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d969482231a.png" alt="" />
            </div>
          </div>

          <dl class="fl">
            <dt>RAKUMART</dt>
            <dd>
              <span @click="$fun.toPage('/company-introduction')">{{
                $fanyi('公司简介')
              }}</span>
            </dd>
            <dd>
              <span @click="$fun.toPage('/about-us')">{{
                $fanyi('关于RAKUMART')
              }}</span>
            </dd>
            <!-- <dd>
              <router-link to="/course">{{ $fanyi("法律条件") }}</router-link>
            </dd> -->
            <dd>
              <span @click="$fun.toPage('/user-guide')">{{
                $fanyi(' 用户指南') }}</span>
            </dd>
            <dd>
              <span @click="$fun.toPage('/faq')">{{ $fanyi(' 常见问题') }}</span>
            </dd>
          </dl>

          <dl class="fl">
            <dt>{{ $fanyi('费用相关') }}</dt>

            <dd>
              <span @click="$fun.toPage('/commission')">{{ $fanyi('佣金') }}</span>

            </dd>
            <dd>
              <span @click="$fun.toPage('/additionalservices')">{{ $fanyi('附加服务') }}</span>

            </dd>
            <dd>
              <span @click="$fun.toPage('/internationalfreight')">{{ $fanyi('国际运费') }}</span>

            </dd>
            <dd>
              <span @click="$fun.toPage('/photography-services')">{{
                $fanyi('摄影服务')
              }}</span>

            </dd>

            <!-- <dd>
              <router-link to="/poundage" >{{
                $fanyi("联系我们")
              }}</router-link>
            </dd> -->
          </dl>
          <dl class="fl">
            <dt>{{ $fanyi('支持') }}</dt>
            <!-- <dd>
              <router-link to="/freight" >{{
                $fanyi("订单跟踪")
              }}</router-link>
            </dd> -->
            <dd>
              <span @click="$fun.toPage('/terms-of-service')">{{
                $fanyi(' 服务条款')
              }}</span>

            </dd>
            <!-- <dd>
              <span @click="$fun.toPage('/legalstatement')">{{ $fanyi('法律警告') }}</span>

            </dd> -->
            <dd>
              <span @click="$fun.toPage('/privacypolicy')">{{ $fanyi(' 隐私政策') }}</span>

            </dd>
            <!-- <dd>
              <router-link to="/option" >{{
                $fanyi("退货和退款")
              }}</router-link>
            </dd> -->
          </dl>
          <!-- <dl class="fl">
            <dt>{{ $fanyi('会员服务(页脚)') }}</dt>
            <dd>
              <router-link to="/footListAll?page=PhotographyServices">{{
                $fanyi('摄影服务')
              }}</router-link>
            </dd> -->
          <!-- <dd>
              <router-link to="/termsService" >{{
                $fanyi("会员服务条款")
              }}</router-link>
            </dd>
            <dd>
              <router-link to="/privacyPolicy" >{{
                $fanyi("隐私政策")
              }}</router-link>
            </dd> -->
          <!-- <dd>
              <router-link to="/commonQuestion" 
                >常见问题</router-link
              >
            </dd> -->
          <!-- </dl> -->
          <!-- <div class="fl cooperativePartner">
            <h1>合作伙伴</h1>
            <div class="con">
              <div><img src="../../assets/img/ebanx.png" alt="" /></div>

              <div><img src="../../assets/img/ywxsp.png" alt="" /></div>

              <div><img src="../../assets/img/fedex.png" alt="" /></div>

              <div><img src="../../assets/img/zgyh.png" alt="" /></div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="footer-pic">
        <p>©Rakumart 2022</p>
        <div class="pic-box">
          <a href="https://usa.pingpongx.com/index" target=_blank> <img src="../../assets/foot-img/pingpong.svg"
              alt="" /></a>
          <a href="https://www.facebook.com/" target=_blank> <img src="../../assets/foot-img/facebook.svg" alt="" /></a>
          <a href="https://www.instagram.com/" target=_blank> <img src="../../assets/foot-img/instagram.04ccb324.svg"
              alt="" /></a>
          <a href="https://www.linkedin.com/" target=_blank> <img src="../../assets/foot-img/Twitter.svg" alt="" /></a>
          <a href="https://www.tiktok.com/" target=_blank> <img src="../../assets/foot-img/tik-tok.svg" alt="" /></a>
          <a href="https://www.youtube.com/" target=_blank> <img src="../../assets/foot-img/youtube.svg" alt="" /></a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      islogin: false,
    }
  },
  created() {
    if (this.$route.path == '/login') {
      this.islogin = false
    } else {
      this.islogin = true
    }
  },
  methods: {},
}
</script>
<style lang="scss" scoped>
@import '../../css/mixin.scss';

dl {
  span {

    &:hover {
      text-decoration: underline;
      cursor: pointer;

    }
  }
}

.footerBackground {
  background-color: white;
  border-top: solid 1px #e8e8e8;

  .footer {
    // min-width: 1400px;
    // height: 400px;
    font-size: 16px;



    .heZuoHuoBan {
      p {
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        color: #999999;
        margin-bottom: 30px;
      }

      .huoBanGroup {
        width: 737px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto 33px;

        .opt {
          width: 158px;
          height: 55px;
          transition: 0.3s;
          background: #ffffff;
          border: 1px solid #e6e6e6;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            border-color: $homePageSubjectColor;
          }
        }
      }
    }

    .w900 {
      // width: 1400px;
      width: 73vw;
      margin: 0 auto;

      .footer-partners {
        text-align: center;
        padding-top: 30px;
        border-top: solid 1px #e9e9e9;

        font-size: 14px;

        font-weight: 400;
        color: #8c8c8c;
      }

      .footer-txt {
        height: 250px;
        display: flex;
        justify-content: space-between;

        dl {
          margin-top: 56px;
          font-size: 18px;

          dt {
            font-size: 16px;
            margin-bottom: 20px;
            font-weight: 600;
            color: #222222;
          }

          dd {
            // line-height: 24px;
            font-size: 14px;
            font-family: Roboto-Regular, Roboto;
            font-weight: 400;
            color: #666666;
            margin-bottom: 9px;

            a {
              color: #666666;

              &:hover {
                text-decoration: underline;
                color: #666666;
              }

              font-size: 14px;
              font-weight: 400;
            }
          }
        }

        .cooperativePartner {
          min-width: 239px;
          margin-top: 50px;
          height: 135px;
          text-align: left;
          color: #222;

          img {
            width: 124px;
            height: 32px;
            margin-bottom: 20px;
          }

          .tit {
            font-size: 15px;
            font-weight: 600;
            margin-bottom: 19px;
          }

          .tel {
            font-size: 22px;
            font-weight: 400;
            margin-bottom: 5px;
          }

          .email {
            font-size: 14px;
            font-family: Roboto-Regular, Roboto;
            font-weight: 400;
            color: #666666;
            font-weight: 400;
            margin-bottom: 10px;
          }

          .time {
            font-size: 13px;
            font-size: 14px;
            font-family: Roboto-Regular, Roboto;
            font-weight: 400;
            color: #666666;
            font-weight: 400;
            margin-bottom: 19px;
          }

          .icons {
            display: flex;

            img {
              width: 328px !important;
              height: 21px !important;
              margin-right: 10px;
              margin-bottom: 0px;
            }
          }
        }
      }
    }

    .footer-pic {
      // width: 1400px;
      width: 73vw;
      margin: 0 auto;
      border-top: 1px solid #e2e2e2;
      padding-top: 43px;
      display: flex;
      padding-bottom: 40px;
      justify-content: space-between;

      // align-items: center;
      p {
        font-size: 14px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #666666;
      }

      .pic-box {
        img {
          width: 30px;
          height: 29px;
          margin-left: 15px;
        }
      }
    }
  }

  .footer:nth-child(2) {
    padding-top: 50px;
    padding-bottom: 30px;
  }
}
</style>
